import { Modal, Spin, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  Action,
  GetSprintKeyActivitiesForInitiativeDocument,
  MitemStatus,
  SprintKeyActivityTable__InitiativeLightFragment,
  SprintKeyActivityTable__MItemFragment,
  TeamResourceType,
} from '../../../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { friendlyDate } from '../../../../../../../services/dateFormats';
import { StatusTag } from '../../../../../../../components/StatusTag';
import { stringSort } from '../../../../../../../services/stringSort';
import { SprintKeyActivityTableSkeleton } from './SprintKeyActivityTable.Skeleton';
import { usePermissionForTeams } from '../../../../../../../hooks/usePermissionForTeams';
import { toMap } from '../../../../../../../services/toMap';
import { EditSprintKeyActivityDrawerWithButton } from '../../../../../strategicPlan/components/EditSkaDrawerWithButton';
import { Btn } from '../../../../../../../components/Button';
import { Suspense, useState } from 'react';
import { ManageSearchIcon } from '../../../../../../../icons/ManageSearch';
import { SprintKeyActivityDetails } from '../../../../../strategicPlan/components/SprintKeyActivityDetails';
import { useTenantPermissions } from '../../../../../../../usePermissions';
import { CreateMilestoneFromSkaWithButton } from '../createSprintKeyActivityForInitiative/CreateMilestoneFromSkaWithButton';
import { StarIcon } from '../../../../../../../icons/Star';
import { match } from 'ts-pattern';
import { Link } from 'react-router-dom';

interface Props {
  initiative: SprintKeyActivityTable__InitiativeLightFragment;
  filters: {
    status: {
      completed: boolean;
      overdue: boolean;
      planned: boolean;
    };
    title: string | null;
    initiativeId: string | null;
  };
}

const matchSprintKeyActivityByStatus = (
  ska: { status: MitemStatus },
  filterStatus: {
    completed: boolean;
    overdue: boolean;
    planned: boolean;
  }
) => {
  if (filterStatus.planned && ska.status === MitemStatus.ACTIVE) return true;
  if (filterStatus.overdue && ska.status === MitemStatus.NOT_COMPLETED) {
    return true;
  }
  const activityStatus = ska.status.toLowerCase();
  return filterStatus[activityStatus as keyof typeof filterStatus];
};

const matchSprintKeyActivityByTitle = (
  ska: { name: string },
  filterTitle: string | null
) => {
  if (filterTitle == null || filterTitle === '') return true;
  return ska.name.toLowerCase().includes(filterTitle.toLowerCase());
};

const matchByInitiativeId = (
  ska: SprintKeyActivityTable__MItemFragment,
  filterInitiativeId: string | null
) => {
  if (filterInitiativeId == null || filterInitiativeId === '') return true;
  if (
    ska.supportsInitiativeLinks.some((si) => si.data.id === filterInitiativeId)
  ) {
    return true;
  }
  if (
    ska.supportsInitiativeLinks.some((si) => si.data.id === filterInitiativeId)
  ) {
    return true;
  }
  return false;
};

export const SprintKeyActivityTable = ({ initiative, filters }: Props) => {
  const { t } = useTranslation();

  const [selectedMitem, setSelectedMitem] =
    useState<SprintKeyActivityTable__MItemFragment | null>(null);

  const { data } = useSuspenseQuery(
    GetSprintKeyActivitiesForInitiativeDocument,
    {
      variables: { initiativeId: initiative.domainId.itemId },
    }
  );

  const { permissionsTenant, loading: loadingTenantPermissions } =
    useTenantPermissions();
  const allowedToCreateMilestone = permissionsTenant?.milestone.some(
    (permittedAction) => permittedAction === Action.CREATE
  );

  const sprintKeyActivities =
    data.sprintKeyActivitiesForInitiatives.oldSprintKAs.filter((m) => {
      if (!matchSprintKeyActivityByStatus(m, filters.status)) return false;
      if (!matchSprintKeyActivityByTitle(m, filters.title)) return false;
      if (!matchByInitiativeId(m, filters.initiativeId)) return false;
      return true;
    });

  const { data: permissionData, loading: loadingPermissions } =
    usePermissionForTeams(TeamResourceType.SPRINT_KA, Action.UPDATE);

  const permissionTeamMap =
    permissionData?.permittedToPerformActionForTeam.permittedResources &&
    toMap(
      permissionData.permittedToPerformActionForTeam.permittedResources,
      (item) => item.teamId
    );

  const milestoneColumns: ColumnProps<SprintKeyActivityTable__MItemFragment>[] =
    [
      {
        title: t('common.deadline'),
        width: 120,
        key: 'deadline',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => stringSort(a.deadline, b.deadline),
        dataIndex: ['deadline'],
        render: (deadline) => {
          return <div className="text-c">{friendlyDate(deadline)}</div>;
        },
      },
      {
        title: t('common.status'),
        key: 'status',
        dataIndex: ['status'],
        width: 150,
        render: (text, milestoneWithStats) => {
          const status = match(milestoneWithStats.status)
            .with(MitemStatus.ACTIVE, () => MitemStatus.PLANNED)
            .with(MitemStatus.NOT_COMPLETED, () => MitemStatus.OVERDUE)
            .otherwise(() => milestoneWithStats.status);

          return <StatusTag status={status} showIcon />;
        },
      },
      {
        title: t('SprintKeyActivityTable.name'),
        key: 'name',
        dataIndex: ['name'],
        render: (text, ska) => {
          const initiatives = new Set<{
            title: string;
            iconId: string;
            colorCode: string;
          }>();

          ska.supportsMilestoneLinks.forEach((sm) => {
            sm.data.metadata.supportsInitiatives.forEach((s) => {
              initiatives.add(s.data.tag);
            });
          });
          ska.supportsInitiativeLinks.map((si) => initiatives.add(si.data.tag));

          return (
            <div>
              <div>
                {ska.milestone && <StarIcon className="space--r" />}
                {text}
              </div>
              <div className="txt--secondary h5">
                {[...initiatives].map((i) => i.title).join(', ')}
              </div>
            </div>
          );
        },
      },
      {
        title: t('SprintKeyActivityTable.team'),
        key: 'team2',
        dataIndex: ['team2', 'name'],
        sorter: (a, b) => stringSort(a.team2.name, b.team2.name),
        render: (text, mitem) => {
          return (
            <Link to={`/team/${mitem.team2.domainId.itemId}`}>{text}</Link>
          );
        },
      },
      {
        title: t('common.actions'),
        dataIndex: 'action',
        key: 'action',
        width: 64,
        render: (_, mitem) => {
          if (loadingPermissions || loadingTenantPermissions) return <Spin />;

          const isAllowedToEdit = !!permissionTeamMap?.[mitem.teamId];
          const isMilestone = !!mitem.milestone;

          if (isAllowedToEdit && allowedToCreateMilestone && isMilestone) {
            return (
              <div className="flx">
                <EditSprintKeyActivityDrawerWithButton ska={mitem} />
                <CreateMilestoneFromSkaWithButton
                  initiative={initiative}
                  sprintKeyActivity={mitem}
                />
              </div>
            );
          }

          if (isAllowedToEdit)
            return <EditSprintKeyActivityDrawerWithButton ska={mitem} />;
          if (isMilestone)
            return (
              <CreateMilestoneFromSkaWithButton
                initiative={initiative}
                sprintKeyActivity={mitem}
              />
            );

          return (
            <Tooltip
              placement="top"
              title={t('SprintKeyActivityTable.noPermissionToEditSka')}
              mouseEnterDelay={0.7}
            >
              -
            </Tooltip>
          );
        },
      },
      {
        width: 64,
        render(_, record) {
          return (
            <Btn
              onClick={() => {
                setSelectedMitem(record);
              }}
              type="link"
              icon={<ManageSearchIcon style={{ fontSize: 16 }} />}
            />
          );
        },
      },
    ];

  return (
    <div>
      <Modal
        open={selectedMitem != null}
        onCancel={() => setSelectedMitem(null)}
        footer={null}
        modalRender={() =>
          selectedMitem && (
            <Suspense
              fallback={
                <SprintKeyActivityDetails.skeleton
                  onClose={() => setSelectedMitem(null)}
                />
              }
            >
              <SprintKeyActivityDetails
                sprintKeyActivity={selectedMitem}
                onClose={() => setSelectedMitem(null)}
              />
            </Suspense>
          )
        }
      />
      <Table
        columns={milestoneColumns}
        dataSource={sprintKeyActivities}
        style={{ borderRadius: '6px' }}
        pagination={{ defaultPageSize: 20 }}
        rowKey={(ska) => ska.id}
      />
    </div>
  );
};

SprintKeyActivityTable.Skeleton = SprintKeyActivityTableSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KEY_ACTIVITY_TABLE__QUERY = gql`
  query getSprintKeyActivitiesForInitiative($initiativeId: ID!) {
    sprintKeyActivitiesForInitiatives(initiativeId: $initiativeId) {
      oldSprintKAs {
        id
        ...SprintKeyActivityTable__MItem
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_MILESTONE_FROM_SKA_WITH_BUTTON__INITIATIVE = gql`
  fragment SprintKeyActivityTable__InitiativeLight on InitiativeLight {
    id
    domainId {
      itemId
      tenantId
    }
    ...CreateMilestoneFromSkaWithButton__InitiativeLight
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KEY_ACTIVITY_TABLE__MITEM = gql`
  fragment SprintKeyActivityTable__MItem on Mitem {
    ...CreateMilestoneFromSkaWithButton__Mitem
    id
    name
    teamId
    status
    deadline
    milestone
    team2 {
      id
      domainId {
        itemId
      }
      name
    }
    supportsInitiativeLinks {
      id
      domainId {
        itemId
      }
      data {
        id
        name
        tag {
          title
          iconId
          colorCode
        }
      }
    }
    supportsMilestoneLinks {
      id
      domainId {
        itemId
      }
      data {
        id
        name
        metadata {
          status
          supportsInitiatives {
            id
            domainId {
              itemId
            }
            data {
              id
              domainId {
                itemId
              }
              name
              tag {
                title
                iconId
                colorCode
              }
            }
          }
        }
      }
    }
    ...SprintKeyActivityDetails_Mitem
    ...EditSprintKeyActivityDrawerWithButton__Mitem
  }
`;

import { ReactNode, createContext } from 'react';
import { useQueryParams } from '../../common/hooks/useQuery';

export enum SorterConjunction {
  AND,
  OR,
}

export const tagsContext = createContext({});

export const TeamSprintProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { queryParams, setParam } = useQueryParams();

  const tagsSelectedForFiltering = queryParams.get('tags')?.split(',') ?? null;
  const setTagsSelectedForFiltering = (tags: string[] | null) => {
    setParam('tags', tags?.join(',') ?? null);
  };

  const _andOrChoiceParam = queryParams.get('tagFilterConjunction');
  const andOrChoice = _andOrChoiceParam
    ? parseInt(_andOrChoiceParam, 10)
    : SorterConjunction.AND;
  const setAndOrChoice = (andOrChoice: SorterConjunction) => {
    setParam('tagFilterConjunction', andOrChoice.toString());
  };

  function setTagsForFiltering(tagsArray: string[] | null) {
    setTagsSelectedForFiltering(tagsArray);
  }

  function setAndOrChoiceForFiltering(sortConjunction: number) {
    setAndOrChoice(sortConjunction);
  }

  const contextValue = {
    tagsSelectedForFiltering,
    setTagsForFiltering,
    setAndOrChoiceForFiltering,
    andOrChoice,
  };

  return (
    <tagsContext.Provider value={contextValue}>{children}</tagsContext.Provider>
  );
};

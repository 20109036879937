import { gql, PureQueryOptions } from '@apollo/client';
import { Drawer, Skeleton } from 'antd';
import { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DrawerTitle } from '../../../../../../components/DrawerTitle';
import { SKADrawerConfirmCloseAlert } from '../../../../../../components/SKADrawerConfirmCloseAlert';
import { EditSprintKeyActivityDrawer__MitemFragment } from '../../../../../../generated/graphql';
import {
  mitemStatusColorLight,
  mitemStatusColorLightContrast,
} from '../../../../../../styleVars';
import { EditSprintKeyActivityDrawerContent } from './EditSprintKeyActivityDrawerContent';

interface Props {
  mitem: EditSprintKeyActivityDrawer__MitemFragment;
  showModal: boolean;
  onCompleted: (data: EditSprintKeyActivityDrawer__MitemFragment) => void;
  onCancel: () => void;
  refetchQueries?: Array<string | PureQueryOptions>;
}

export const EditSprintKeyActivityDrawer = ({
  mitem,
  showModal,
  onCompleted,
  onCancel,
  refetchQueries,
}: Props) => {
  const { t } = useTranslation();

  const [formIsDirty, setFormIsDirty] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);

  // TODO: Should be changed, need to handle intercom on a higher level
  const isAccMeeting = window.location.href.includes('acceleration-meeting');

  const handleCancel = () => {
    if (formIsDirty) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
      onCancel();
    }
  };

  const resetConfirmationData = () => {
    setFormIsDirty(false);
    setShowWarning(false);
    setConfirmed(false);
  };

  useEffect(() => {
    if (confirmed) {
      resetConfirmationData();
      onCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed]);

  useEffect(() => {
    if (!isAccMeeting) {
      (window as any).Intercom?.('update', {
        hide_default_launcher: showModal,
      });

      // sometimes the drawer is unmounted rather than getting the prop showModal={false}
      // this will make sure we show the intercom widget again in that case
      return () =>
        (window as any).Intercom?.('update', {
          hide_default_launcher: false,
        });
    }

    if (!showModal) {
      setFormIsDirty(false);
      setShowWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, isAccMeeting]);

  const statusBackgroundColor = mitemStatusColorLight[mitem.status];
  const statusTextColor = mitemStatusColorLightContrast[mitem.status];

  return (
    <Drawer
      title={
        <DrawerTitle style={{ color: statusTextColor }}>
          {t(`common.sprintKeyActivity.STATUSES.${mitem.status}`)}
        </DrawerTitle>
      }
      open={showModal}
      onClose={handleCancel}
      width={420}
      destroyOnClose
      styles={{
        header: { backgroundColor: statusBackgroundColor },
        content: {
          borderLeft: `8px solid ${statusBackgroundColor}`,
        },
      }}
    >
      {showWarning && (
        <SKADrawerConfirmCloseAlert
          onConfirm={() => setConfirmed(true)}
          onClose={() => setShowWarning(false)}
        />
      )}
      <Suspense fallback={<Skeleton paragraph={{ rows: 3 }} />}>
        <EditSprintKeyActivityDrawerContent
          teamId={mitem.teamId}
          skaId={mitem.id}
          refetchQueries={refetchQueries}
          setFormIsDirty={setFormIsDirty}
          onCancel={handleCancel}
          onCompleted={(data) => {
            onCompleted(data);
            resetConfirmationData();
          }}
        />
      </Suspense>
    </Drawer>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditSprintKeyActivityDrawerFragment = gql`
  fragment EditSprintKeyActivityDrawer__Mitem on Mitem {
    id
    teamId
    status
    ...EditSprintKeyActivityDrawerContent__Mitem
  }
`;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../../components/Button';
import { FullscreenModal } from '../../../components/FullscreenModal';
import { CompanyInitiativeReportSection } from './CompanyInitiativeReportSection/CompanyInitiativeReportSection';
import { CompanyMigReportSection } from './CompanyMigReportSection/CompanyMigReportSection';
import { exportReportToPdf } from './exportReportToPdf';
import './ReportGenerator.page.less';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { OverdueMilestonesReportSection } from './OverdueMilestonesReportSection/OverdueMilestonesReportSection';
import cx from 'classnames';
import { useTenantDetails } from '../../../hooks/useTenantDetails';

export const ReportGeneratorButton = () => {
  const { t } = useTranslation();
  const { tenant } = useTenantDetails();
  const [isOpen, setIsOpen] = useState(false);
  const [isPreviewMode, setPreviewMode] = useState(false);
  const [generatingPdf, setGeneratingPdf] = useState(false);

  return (
    <>
      <Btn onClick={() => setIsOpen(true)}>
        {t('ReportGeneratorButton.createReportButton')}
      </Btn>
      <FullscreenModal
        open={isOpen}
        keyboard={false}
        onCancel={() => setIsOpen(false)}
        onClose={() => setIsOpen(false)}
        destroyOnClose
        footer={
          <div className="flx">
            <div className="mr--auto ml--auto">
              <Btn className="mr" onClick={() => setIsOpen(false)}>
                {t('ReportGeneratorButton.closeButton')}
              </Btn>
              <Btn
                className="mr"
                onClick={() => setPreviewMode(!isPreviewMode)}
                icon={isPreviewMode ? <EditOutlined /> : <EyeOutlined />}
              >
                {isPreviewMode
                  ? t('ReportGeneratorButton.editButton')
                  : t('ReportGeneratorButton.previewButton')}
              </Btn>
              <Btn
                type="primary"
                loading={generatingPdf}
                onClick={() => {
                  setGeneratingPdf(true);
                  setPreviewMode(true);
                  setTimeout(async () => {
                    await exportReportToPdf(tenant?.name || 'report');

                    setGeneratingPdf(false);
                  }, 200);
                }}
              >
                {t('ReportGeneratorButton.exportPdfButton')}
              </Btn>
            </div>
          </div>
        }
      >
        <>
          <div
            id="report-container"
            className={cx('content mt', { 'preview-mode': isPreviewMode })}
          >
            <CompanyMigReportSection isPreviewMode={isPreviewMode} />
            <CompanyInitiativeReportSection isPreviewMode={isPreviewMode} />
            <OverdueMilestonesReportSection isPreviewMode={isPreviewMode} />
          </div>
        </>
      </FullscreenModal>
    </>
  );
};

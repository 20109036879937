import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { ActivityCardsPage } from './cardPage/ActivityCards.page';
import { ActivityListPage } from './listPage/ActivityList.page';
import { Radio } from 'antd';

enum ActivityRoutes {
  List = 'list',
  Card = 'card',
}

export const TeamActivitiesRoutes = () => {
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  // Get current view from path
  const currentView = location.pathname.endsWith('/card')
    ? ActivityRoutes.Card
    : ActivityRoutes.List;

  const options = [
    { label: 'List', value: ActivityRoutes.List },
    { label: 'Card', value: ActivityRoutes.Card },
  ];

  const handleViewChange = (newPath: ActivityRoutes) => {
    history.push(`${match.url}/${newPath}`);
  };

  return (
    <>
      <Radio.Group
        options={options}
        onChange={({ target: { value } }) => handleViewChange(value)}
        value={currentView}
        optionType="button"
      />
      <Switch>
        <Route exact path={match.path + '/list'}>
          <ActivityListPage />
        </Route>
        <Route exact path={match.path + '/card'}>
          <ActivityCardsPage />
        </Route>
        <Redirect exact from={match.url + '/'} to={match.url + '/list'} />
      </Switch>
    </>
  );
};

import { gql, useQuery } from '@apollo/client';
import { ReportSection } from '../ReportSection';
import { GetTopInitiativesForOverdueTrackerInReportDocument } from '../../../../generated/graphql';
import { OverdueMilestones } from './components/OverdueMilestones';
import { useTranslation } from 'react-i18next';

interface Props {
  isPreviewMode: boolean;
}

export const OverdueMilestonesReportSection = ({ isPreviewMode }: Props) => {
  const { t } = useTranslation();
  const { data } = useQuery(GetTopInitiativesForOverdueTrackerInReportDocument);

  return (
    <ReportSection
      isPreviewMode={isPreviewMode}
      title={t('OverdueMilestonesReportSection.title')}
      subTitle={t('OverdueMilestonesReportSection.subTitle')}
    >
      {data?.topInitiativeReport2.initiatives.map((initiative) => (
        <OverdueMilestones
          key={initiative.id}
          isPreviewMode={isPreviewMode}
          initiative={initiative.initiative}
        />
      ))}
    </ReportSection>
  );
};

//eslint-disable-next-line
const INITIATIVES_QUERY = gql`
  query GetTopInitiativesForOverdueTrackerInReport(
    $tenantId: ID
    $filter: TopInitiativeFilter
  ) {
    topInitiativeReport2(tenantId: $tenantId, filter: $filter) {
      initiatives {
        id
        initiative {
          id
          ...OverdueMilestones_initiative
        }
      }
    }
  }
`;

import { Divider } from 'antd';
import { useState } from 'react';
import './ReportSection.less';
import cx from 'classnames';

interface Props {
  isPreviewMode: boolean;
  title: string;
  subTitle?: string;
  pageBreak?: boolean;
  children?: React.ReactNode;
}

export const ReportSection = ({
  isPreviewMode,
  title,
  subTitle,
  pageBreak = true,
  children,
}: Props) => {
  const [excludeSection, setExcludeSection] = useState(false);

  return (
    <div className="mt--xxl borderBottom--skipLast pb--l">
      <div
        className={cx({
          'page-break': pageBreak,
          'exclude-from-pdf': excludeSection,
        })}
      >
        <div className={'center-content'}>
          <h1 className="mb--xs">{title}</h1>
          {subTitle && <h4 className="txt--secondary mb">{subTitle}</h4>}
        </div>
        {!isPreviewMode && (
          <div
            className="link center-content"
            onClick={() => setExcludeSection(!excludeSection)}
          >
            {excludeSection ? 'Include whole section' : 'Exclude whole section'}
          </div>
        )}
      </div>
      <div className="mb--l exclude-from-pdf">
        <Divider />
      </div>

      {!excludeSection && children}
    </div>
  );
};

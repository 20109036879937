import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccelerationMeetingContainer_MitemAccelerationMeetingSprintsFragment,
  AccelerationMeetingContainer_TeamAccelerationMeetingFragment,
} from '../../../../../generated/graphql';
import {
  ConfigStep,
  WizardNavigationProvider,
} from '../../../../../components/WizardNavigationProvider';
import { AccelerationMeeting } from './accelerationMeetingContainer/AccelerationMeeting';
import { AkpiCommitment } from './accelerationMeetingContainer/meetingSteps/AkpiCommitment';
import { MeetingSummary } from './accelerationMeetingContainer/meetingSteps/MeetingSummary';
import { CurrentSprintProgress } from './accelerationMeetingContainer/meetingSteps/SprintTermStep/CurrentSprintProgress';
import { PreviousSprintSummary } from './accelerationMeetingContainer/meetingSteps/SprintTermStep/PreviousSprintSummary';
import { StartNewSprint } from './accelerationMeetingContainer/meetingSteps/SprintTermStep/StartNewSprint';
import { AccelerationMeetingProvider } from './AccelerationMeetingProvider';
import { WeeklyKeyActivitySummary } from './accelerationMeetingContainer/meetingSteps/PreviousPeriodSummary/WeeklyKeyActivitySummary';
import { SprintKeyActivitySummary } from './accelerationMeetingContainer/meetingSteps/PreviousPeriodSummary/SprintKeyActivitySummary';
import { gql } from '@apollo/client';

interface Props {
  team: {
    name: string;
    domainId: { itemId: string };
  };
  accelerationMeeting: AccelerationMeetingContainer_TeamAccelerationMeetingFragment;
  mitemSprints: AccelerationMeetingContainer_MitemAccelerationMeetingSprintsFragment | null;
  defaultOnClose: () => void;
  setTitle: (title: ReactNode) => void;
  previousPeriodConfig: {
    hasFirstMeeting: boolean;
    hasSprintKeyActivities: boolean;
    hasWeeklyKeyActivities: boolean;
  };
}

export const AccelerationMeetingContainer = ({
  team,
  defaultOnClose,
  setTitle,
  mitemSprints,
  accelerationMeeting,
  previousPeriodConfig,
}: Props) => {
  const { t } = useTranslation();

  const currentSprint = mitemSprints?.currentSprint;
  const previousSprint = mitemSprints?.previousSprint;

  const [isMitemTeamThatShouldStartANewSprint] = useState(() => {
    const isMitemTeam = currentSprint != null;
    const sprintStarted = !!currentSprint?.locked;
    return isMitemTeam && !sprintStarted;
  });

  const [isMitemTeamInASprint] = useState(() => {
    const isMitemTeam = currentSprint != null;
    const sprintStarted = !!currentSprint?.locked;
    return isMitemTeam && sprintStarted;
  });

  // The title is used in an upper effect, thats why we are wrapping this component with useMemo
  const mitemStepTitle = useMemo(
    () => <span>{t('AccelerationMeetingView.stepMitems')}</span>,
    [t]
  );

  const weeklyKeyActivities = accelerationMeeting.akpis;

  const wizardConfig: ConfigStep[] = [
    {
      title: t('AccelerationMeetingView.lastPeriodSummary'),
      type: 'ConfigGroupStep',
      key: 'KeyActivitySummary',
      skipStep:
        !previousPeriodConfig.hasSprintKeyActivities &&
        !previousPeriodConfig.hasWeeklyKeyActivities,
      subSteps: [
        {
          title: 'Sprint Key Activities',
          type: 'ConfigComponentStep',
          key: 'SprintKeyActivities',
          skipStep: !previousPeriodConfig.hasSprintKeyActivities,
          component: () => (
            <SprintKeyActivitySummary
              teamId={team.domainId.itemId}
              sprints={mitemSprints}
            />
          ),
        },
        {
          title: 'Weekly Key Activities',
          type: 'ConfigComponentStep',
          key: 'WeeklyKeyActivities',
          skipStep: !previousPeriodConfig.hasWeeklyKeyActivities,
          component: () => (
            <WeeklyKeyActivitySummary teamId={team.domainId.itemId} />
          ),
        },
      ],
    },
    {
      title: t('AccelerationMeetingView.sprintCommitments'),
      type: 'ConfigComponentStep',
      key: 'CommitToSprintKeyActivities',
      skipStep: !isMitemTeamInASprint,
      component: () => (
        <CurrentSprintProgress
          currentSprint={currentSprint!}
          teamId={team.domainId.itemId}
        />
      ),
    },
    {
      title: mitemStepTitle,
      type: 'ConfigGroupStep',
      key: 'Sprints',
      skipStep: !isMitemTeamThatShouldStartANewSprint,
      subSteps: [
        {
          title: t('AccelerationMeetingView.previousSprintSummary'),
          type: 'ConfigComponentStep',
          key: 'PrevSprint',
          isMandatory: !previousSprint?.finalized,
          skipStep: previousSprint == null,
          component: () =>
            previousSprint && (
              <PreviousSprintSummary
                previousSprint={previousSprint}
                teamId={team.domainId.itemId}
              />
            ),
        },
        {
          title: t('AccelerationMeetingView.startNewSprint'),
          type: 'ConfigComponentStep',
          key: 'NewSprint',
          isMandatory: true,
          component: () => (
            <StartNewSprint
              currentSprint={currentSprint!}
              teamId={team.domainId.itemId}
            />
          ),
        },
        {
          title: t('AccelerationMeetingView.sprintCommitments'),
          type: 'ConfigComponentStep',
          key: 'CommitToSprintKeyActivities',
          component: () => (
            <CurrentSprintProgress
              currentSprint={currentSprint!}
              teamId={team.domainId.itemId}
            />
          ),
        },
      ],
    },
    {
      title: t('AccelerationMeetingView.stepAkpiCommitment'),
      type: 'ConfigGroupStep',
      key: 'AkpiCommitment',
      skipStep: weeklyKeyActivities.length === 0,
      subSteps: weeklyKeyActivities.map((weeklyKeyActivity) => ({
        title: weeklyKeyActivity.name,
        key: weeklyKeyActivity.name,
        type: 'ConfigComponentStep',
        component: () => (
          <AkpiCommitment key={weeklyKeyActivity.id} akpi={weeklyKeyActivity} />
        ),
      })),
    },
    {
      title: t('AccelerationMeetingView.stepSummary'),
      type: 'ConfigComponentStep',
      key: 'MeetingSummary',
      component: () => (
        <MeetingSummary
          weeklyKeyActivities={weeklyKeyActivities}
          teamId={team.domainId.itemId}
        />
      ),
    },
  ];

  return (
    <WizardNavigationProvider
      config={wizardConfig}
      defaultStepKey={
        !accelerationMeeting.isNewMeeting ? 'MeetingSummary' : null
      }
    >
      <AccelerationMeetingProvider
        teamId={team.domainId.itemId}
        isNewMeeting={!!accelerationMeeting.isNewMeeting}
        members={accelerationMeeting.team2.members.map((m) => m.user.data)}
        sprintKaCommitmentsDTO={accelerationMeeting.mitemCommitments ?? []}
        weeklyKeyActivities={weeklyKeyActivities}
        weeklyKaCommitmentsDTO={
          accelerationMeeting.accelerationKpiCommitments ?? []
        }
      >
        <AccelerationMeeting
          key={team.domainId.itemId}
          hasKeyActivities={
            weeklyKeyActivities.length + (currentSprint?.mitems ?? []).length >
            0
          }
          setTitle={setTitle}
          defaultOnClose={defaultOnClose}
        />
      </AccelerationMeetingProvider>
    </WizardNavigationProvider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACCELERATION_MEETING_CONTAINER_FRAGMENT = gql`
  fragment AccelerationMeetingContainer_TeamAccelerationMeeting on TeamAccelerationMeeting {
    teamId # required by keyFields in typePolicy
    periodEndDate # required by keyFields in typePolicy
    isNewMeeting
    team2 {
      id
      domainId {
        itemId
      }
      name
      members {
        roles
        user {
          id
          data {
            ...AccelerationMeetingProvider_Member
          }
        }
      }
    }
    akpis {
      id
      name
      ...AkpiCommitment_Akpi
      ...MeetingSummary_Akpi
      ...AccelerationMeetingProvider_Akpi
    }
    mitemCommitments {
      ...AccelerationMeetingProvider_MitemCommitments
    }

    accelerationKpiCommitments {
      ...AccelerationMeetingProvider_AccelerationKpiCommitments
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACCELERATION_MEETING_SPRINTS_FRAGMENT = gql`
  fragment AccelerationMeetingContainer_MitemAccelerationMeetingSprints on MitemAccelerationMeetingSprints {
    currentSprint {
      id
      locked
      ...CurrentSprintProgress_MitemSprint
      ...StartNewSprint_MitemSprint
    }
    previousSprint {
      id
      ...PreviousSprintSummary_MitemSprint
    }
    ...SprintKeyActivitySummary_MitemAccelerationMeetingSprints
  }
`;

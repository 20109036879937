import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  TableMitemDetails_InitiativeWithLinksFragment,
  TableMitemDetails_MilestoneWithLinksFragment,
  TableMitemDetails_MitemFragment,
} from '../../generated/graphql';
import './TableMitemDetails.less';
import { MitemHistory } from '../../appPages/team/sprint/common/components/MitemHistory';
import { InitiativeTag } from '../initiative/InitiativeTag';
import { FlagOutlined } from '@ant-design/icons';

interface Props {
  mitem: TableMitemDetails_MitemFragment;
}

export const TableMitemDetails = ({ mitem }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="font-size--sm">
      <div className="TableMitemDetails__section">
        <h5 className="TableMitemDetails__label">
          {t('common.definitionOfDone')}:
        </h5>
        {mitem.definitionOfDone}
      </div>
      <div className="TableMitemDetails__section">
        <h5 className="TableMitemDetails__label">
          {t('TableMitemDetails.supportsMig')}:
        </h5>
        {mitem.supportedMigs.map((mig) => mig.name).join(', ')}
      </div>
      <div className="TableMitemDetails__section">
        <h5 className="TableMitemDetails__label">{t('common.alignment')}:</h5>
        <div>
          {groupInitiativesAndMilestones(mitem).map(
            ({ initiative, milestones }) => (
              <div key={initiative.id} className="flx mb">
                <div className="TableMitemDetails__initiative">
                  <div className="flx flx--ai-center">
                    <InitiativeTag tag={initiative.tag} />
                    {/* <Btn type="link" size="small">
                      <SearchOutlineIcon />
                    </Btn> */}
                    {milestones.length > 0 && (
                      <div className="TableMitemDetails__line" />
                    )}
                  </div>
                </div>
                {milestones.length > 0 && (
                  <div>
                    {milestones.map((ms) => (
                      <div key={ms.id} className="mb">
                        <div className="bold flx mb--xs flx--ai-center">
                          <FlagOutlined className="font-size--default mr--s" />
                          {ms.name}
                          {/* <Btn type="link" size="small">
                            <SearchOutlineIcon />
                          </Btn> */}
                        </div>
                        <span className="bold">{t('common.description')}:</span>
                        {ms.description}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </div>
      <div className="TableMitemDetails__section">
        <h5 className="TableMitemDetails__label">{t('common.history')}:</h5>
        <div className="mt">
          <MitemHistory teamId={mitem.teamId} mitemId={mitem.id} />
        </div>
      </div>
    </div>
  );
};

interface GroupedInitiative {
  initiative: TableMitemDetails_InitiativeWithLinksFragment;
  milestones: TableMitemDetails_MilestoneWithLinksFragment[];
}

const groupInitiativesAndMilestones = (
  mitem: TableMitemDetails_MitemFragment
) => {
  const groupedMap = new Map<string, GroupedInitiative>();

  // Add initiatives from milestone links
  mitem.supportsMilestoneLinks.forEach((ms) => {
    const initiative = ms.data.metadata.supportsInitiatives[0]?.data;
    if (initiative) {
      if (!groupedMap.has(initiative.id)) {
        groupedMap.set(initiative.id, { initiative, milestones: [] });
      }
      groupedMap.get(initiative.id)!.milestones.push(ms.data);
    }
  });

  // Add direct initiative links
  mitem.supportsInitiativeLinks.forEach((i) => {
    if (!groupedMap.has(i.data.id)) {
      groupedMap.set(i.data.id, { initiative: i.data, milestones: [] });
    }
  });

  return Array.from(groupedMap.values());
};

//eslint-disable-next-line
const MITEM_DETAILS_FRAGMENT = gql`
  fragment TableMitemDetails_Mitem on Mitem {
    id
    definitionOfDone
    noMigAssociation
    teamId
    supportsMilestoneLinks {
      id
      domainId {
        itemId
      }
      data {
        ...TableMitemDetails_MilestoneWithLinks
      }
    }
    supportsInitiativeLinks {
      id
      domainId {
        itemId
      }
      data {
        ...TableMitemDetails_InitiativeWithLinks
      }
    }
    supportedMigs {
      id
      name
      domainId {
        teamId
        itemId
      }
    }
  }
`;

//eslint-disable-next-line
const INITIATIVE_WITH_LINKS_FRAGMENT = gql`
  fragment TableMitemDetails_InitiativeWithLinks on InitiativeWithLinks {
    id
    name
    tag {
      title
      iconId
      colorCode
    }
  }
`;

//eslint-disable-next-line
const MILESTONE_WITH_LINKS_FRAGMENT = gql`
  fragment TableMitemDetails_MilestoneWithLinks on MilestoneWithLinks {
    id
    name
    description
    metadata {
      supportsInitiatives {
        id
        data {
          ...TableMitemDetails_InitiativeWithLinks
        }
      }
    }
  }
`;

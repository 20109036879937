import { Alert } from 'antd';
import { gql, useSuspenseQuery } from '@apollo/client';
import { Action, GetTeamDetailsDocument } from '../../generated/graphql';
import { useParams, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { TeamPageTitleMenu } from './common/TeamPageTitleMenu';
import { Switch, Route, Redirect } from 'react-router-dom';
import { TeamMembersPage } from './members/TeamMembersPage';
import { TeamSetupRoutes } from './setup/TeamSetupRoutes';
import { Helmet } from 'react-helmet';
import './Team.page.less';
import { ArchiveFilledIcon } from '../../icons/ArchiveIcon';
import { TeamSprintProvider } from './sprint/common/TeamSprintProvider';
import { TeamOverviewPage } from './overview/TeamOverview.page';
import { PermissionChecker } from '../../PermissionChecker';
import { TeamSprintRoutes } from './sprint/TeamSprintRoutes';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';
import { ErrorPage } from '../../components/ErrorPage';
import { TeamActivitiesRoutes } from './activities/TeamActivitiesRoutes';

export const TeamPage = () => {
  const { t } = useTranslation();

  const { teamId } = useParams<{ teamId: string }>();
  const match = useRouteMatch();

  const { data } = useSuspenseQuery(GetTeamDetailsDocument, {
    variables: { teamId: teamId! },
    fetchPolicy: 'no-cache',
  });

  const archived = data.team2.archived;

  return (
    <TeamSprintProvider>
      {data?.team2 && <TeamPageTitleMenu {...data?.team2} />}
      {data?.team2 && (
        <Helmet
          defaultTitle={data.team2.name + ' - Howwe'}
          titleTemplate={`${data.team2.name} | %s - Howwe`}
        />
      )}

      <div className="content">
        {archived && (
          <Alert
            className="mt"
            icon={<ArchiveFilledIcon style={{ fontSize: 50, color: 'grey' }} />}
            type="error"
            message={t('TeamPage.archived.title')}
            description={t('TeamPage.archived.subTitle')}
            showIcon
          />
        )}
        <Switch>
          <Redirect
            exact
            from={match.url + '/setup'}
            to={match.url + '/setup/overview'}
          />
          <Route path={match.url + '/overview'}>
            <ErrorBoundary
              FallbackComponent={ErrorPage}
              onError={(error) => Sentry.captureException(error)}
            >
              <Suspense fallback={<TeamOverviewPage.Skeleton />}>
                <TeamOverviewPage team={data?.team2} />
              </Suspense>
            </ErrorBoundary>
          </Route>
          <Route path={match.path + '/members'}>
            <TeamMembersPage />
          </Route>

          <Route path={match.path + '/setup'}>
            <PermissionChecker
              resourceOwner={{
                type: 'TeamResource',
                teamId: teamId,
                requestedAction: [
                  {
                    resource: 'mig',
                    action: [Action.CREATE, Action.UPDATE],
                  },
                  {
                    resource: 'weeklyKA',
                    action: [Action.CREATE, Action.UPDATE],
                  },
                ],
              }}
            >
              <TeamSetupRoutes teamId={teamId} />
            </PermissionChecker>
          </Route>

          <Route path={match.path + '/activities'}>
            <PermissionChecker
              resourceOwner={{
                type: 'TeamResource',
                teamId: teamId,
                requestedAction: {
                  resource: 'sprintKA',
                  action: Action.READ,
                },
              }}
            >
              <TeamActivitiesRoutes />
            </PermissionChecker>
          </Route>

          <Route path={match.path + '/sprint'}>
            <PermissionChecker
              resourceOwner={{
                type: 'TeamResource',
                teamId: teamId,
                requestedAction: {
                  resource: 'sprintKA',
                  action: Action.READ,
                },
              }}
            >
              <TeamSprintRoutes />
            </PermissionChecker>
          </Route>

          <Redirect from={match.url} to={match.url + '/overview'} />
        </Switch>
      </div>
    </TeamSprintProvider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_DETAILS = gql`
  query getTeamDetails($teamId: ID!) {
    team2(teamId: $teamId) {
      archived
      name
      id
      domainId {
        itemId
      }
    }
  }
`;
